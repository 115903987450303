<template>
<div>

    <v-row>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom" color="teal">
                <h3>{{ $service.formatCurrency(totals.totalDeposit) }} د.ع</h3>
                <h4 class="mt-2">مجموع الايداع</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom" color="pink">
                <h3>{{ $service.formatCurrency(totals.totalWithdraw) }} د.ع</h3>
                <h4 class="mt-2">مجموع السحب</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom" color="indigo">
                <h3>{{ $service.formatCurrency(totals.totalDeposit - totals.totalWithdraw) }} د.ع</h3>
                <h4 class="mt-2">المبلغ الصافي</h4>
            </v-alert>
        </v-col>
    </v-row>

    <v-divider class="mb-4" />

    <h3 class="mb-2">اصناف القيود</h3>
    <v-row>
        <v-col cols="12" md="3" v-for="(cat,index) in transactionCategories" :key="index">
            <v-alert colored-border style="cursor: pointer;" @click="filterCategory(cat,index)" text class="text-center btn" :border="selectedItem == index ? 'bottom' : ''" color="third">
                <h3 :class="cat.transactionType == 1 ? 'teal--text' : 'pink--text'">{{ $service.formatCurrency(cat.total) }} د.ع</h3>
                <h4 class="mt-2" :class="cat.transactionType == 1 ? 'teal--text' : 'pink--text'">{{ cat.name }}</h4>
            </v-alert>
        </v-col>
    </v-row>

    <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class="btn" block>
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3> إنشاء قيد جديد</h3>
            </v-btn>
        </v-col>
        <v-spacer />

        <aTextField v-debounce:500ms="getItems" v-model="filter.title" label="بحث حسب عنوان القيد" :cols="12" :md="2" :required="false" :fieldTitle="true" :dense="true" />
        <!-- <aAutoComplete @input="getItems()" v-model="filter.transactionCategoryId" :loading="$global.state.loading" :fieldTitle="true" :items="transactionCategories" item-text="name" item-value="id" label="صنف القيد" :cols="12" :md="2" :required="false" :dense="true"/> -->
        <aAutoComplete @input="getItems()" v-if="$store.state.user.userType.id == 1" v-model="filter.officeId" :loading="$global.state.loading" :fieldTitle="true" :items="offices" item-text="name" item-value="id" label="المكتب" :cols="12" :md="2" :required="false" :dense="true" />
        <aDatePicker @input="getItems()" v-model="filter.fromDate" :loading="$global.state.loading" :fieldTitle="false" item-text="name" item-value="id" title="من تاريخ" :cols="12" :md="2" :required="false" :dense="true" />
        <aDatePicker @input="getItems()" v-model="filter.toDate" :loading="$global.state.loading" :fieldTitle="false" item-text="name" item-value="id" title="الى تاريخ" :cols="12" :md="2" :required="false" :dense="true" />

    </v-row>

    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <template v-slot:item.amount="{item}">
                <v-chip label v-if="item.transactionCategory.transactionType == 1" class="teal--text"><strong>{{ $service.formatCurrency(item.amount) }}+</strong> </v-chip>
                <v-chip label v-if="item.transactionCategory.transactionType == -1" class="error--text"><strong>{{ $service.formatCurrency(item.amount) }}-</strong> </v-chip>
            </template>

            <template v-slot:item.note="{item}">
                <div>{{ item.note == "" ? "لا يوجد" : item.note }}</div>
            </template>

            <template v-slot:item.creator="{item}">
                <div v-if="item.creator != null">{{ item.creator.fullName }}</div>
                <div v-else>النظام</div>
            </template>

            <template v-slot:item.transactionCategory="{item}">
                {{item.transactionCategory.name}} -
                (
                <span v-if="item.transactionCategory.transactionType == -1" class="error--text">سحب</span>
                <span v-if="item.transactionCategory.transactionType == 1" class="teal--text">ايداع</span> )
            </template>

            <template v-slot:item.created="{item}">
                <div><span class="grey--text">({{ item.created.substr(11,8) }})</span> {{ item.created.substr(0,10)  }} </div>
            </template>

            <template v-slot:item.actions="{item}">

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" x-small icon>
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id, index)" v-on="on" icon x-small class="mr-2">
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
</div>
</template>

<style>
#orders {
    border-collapse: collapse;
    width: 100%;
}

#orders td,
#orders th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 4px;
}

#orders tr:nth-child(even) {
    background-color: #f2f2f2;
}

#orders tr:hover {
    background-color: #ddd;
}

#orders th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #202020;
    color: white;
}
</style>

<script>
export default {

    components: {
        Dialog: () => import("./dialog"),
    },

    data() {
        return {
            selectedItem: null,
            loading: false,
            items: [],
            offices: [],
            transactionCategories: [],
            currentIndex: null,
            filter: {
                title: '',
                transactionCategoryId: '',
                officeId: '',
                fromDate: '',
                toDate: ''
            },
            totals: {
                totalDeposit: 0,
                totalWithdraw: 0
            },
            headers: [{
                    text: "منشئ القيد",
                    value: "creator"
                },
                {
                    text: "العنوان",
                    value: "title"
                },
                {
                    text: "صنف القيد",
                    value: "transactionCategory"
                },
                {
                    text: "المبلغ",
                    value: "amount"
                },
                {
                    text: "التاريخ",
                    value: "created"
                },
                {
                    text: "ملاحظة",
                    value: "note"
                },
                // {
                //     text: "الاجراءات",
                //     value: "actions"
                // }
            ],

        }
    },

    created() {

        this.getItems()
        this.getOffices()
        this.getTransactionCategories()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);

    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {

        filterCategory(cat,index) {
            this.selectedItem = index
            if(this.filter.transactionCategoryId == cat.id){
                this.filter.transactionCategoryId = ''
                this.selectedItem = null
            }else{
                this.filter.transactionCategoryId = cat.id
            }
            this.getItems()
        },

        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Transaction?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}
                &TransactionCategoryId=${this.filter.transactionCategoryId}&FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}&Title=${this.filter.title}&OfficeId=${this.filter.officeId}`)
                this.items = res.data.result
                this.totals = res.data.totalCounts
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getTransactionCategories() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`TransactionCategory`)
                this.transactionCategories = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getOffices() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Office`)
                this.offices = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fetch-data')

        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },

    },

}
</script>
